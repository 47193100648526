import React, { useState } from 'react';
import { register, verify, resendEmail } from '../services/apiServices';
import Validation from '../components/Validation';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../services/authContext';
import NusmarkLogoDark from "../media/Nusmark-Logo-Black-Lighter-Smoothed.png";
import { AnimatedBackground } from 'animated-backgrounds';

export default function Register() {
  const [formData, setFormData] = useState({
    businessName: '',
    email: '',
    confirmEmail: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    agreeToTerms: false,
  });

  const [error, setError] = useState('');
  const [isValidationOpen, setIsValidationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nameRegex = /^[a-zA-Z0-9]+$/; 
    if (!nameRegex.test(formData.businessName)) {
      setError('Business name must not contain spaces or special characters');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    if (formData.email !== formData.confirmEmail) {
      setError('Emails do not match');
      return;
    }
    if (!formData.agreeToTerms) {
      setError('You must agree to the terms and conditions');
      return;
    }

    setIsLoading(true);

    register({
      business_name: formData.businessName,
      email: formData.email,
      phone_number: formData.phoneNumber,
      password: formData.password,
    })
    .then((response) => {
      if (response.status === 200 && response.data.status === 'success') {
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
        }
        setIsValidationOpen(true);
        setError('');
        setIsLoading(false);
      } else {
        setError(response.message || 'Registration failed');
        setIsLoading(false);
      }
    })
    .catch((err) => {
      console.error(err);
      setError(err.response.data.message || 'Registration failed');
      setIsLoading(false);
    });
  };

  const handleResend = () => {
    const token = localStorage.getItem("token");
    try{
      resendEmail({ access_token: token })
      .then((response) => {
        if (response.status !== 200 || response.data.status !== 'success') {
          window.alert('Verification code resent');
          setError(response.message || 'Resend failed');
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.response.data.message || 'Resend failed');
      });
    } catch (err) {
      console.error(err);
      setError('Resend failed');
    }
  };

  const handleValidate = (code) => {
    verify({
      access_token: localStorage.getItem('token'),
      verification_code: code,
    })
      .then((response) => {
        if (response.status === 200 && response.data.status === 'success') {
          setIsAuthenticated(true);
          navigate("/dashboard");
        } else {
          setError(response.message || 'Validation failed');
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.response.data.message || 'Validation failed');
      });
  };

  return (
    <div className="relative flex flex-col justify-center md:flex-row md:justify-start min-h-screen">
      
      <div className="flex flex-col items-center justify-center bg-white w-full md:w-1/2 p-6 md:p-10 lg:p-16 text-gray-900 shadow-lg">
        <img src={NusmarkLogoDark} alt="Nusmark Logo" className="h-12 w-auto mb-10" />
        <h2 className="text-4xl font-bold text-center mb-6">REGISTER</h2>
        {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
        
        <form onSubmit={handleSubmit} className="w-full space-y-4 flex flex-col items-center">
          <input 
            type="text" 
            name="businessName" 
            placeholder="Business Name" 
            value={formData.businessName} 
            onChange={handleChange} 
            required 
            className="w-3/4 max-w-sm px-4 py-3 rounded-md bg-gray-100 border border-gray-300 focus:outline-none" />
          <input 
            type="email" 
            name="email" 
            placeholder="Email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
            className="w-3/4 max-w-sm px-4 py-3 rounded-md bg-gray-100 border border-gray-300 focus:outline-none" />
          <input 
            type="email" 
            name="confirmEmail" 
            placeholder="Confirm Email" 
            value={formData.confirmEmail} 
            onChange={handleChange} 
            required 
            className="w-3/4 max-w-sm px-4 py-3 rounded-md bg-gray-100 border border-gray-300 focus:outline-none" />
          <input 
            type="tel" 
            name="phoneNumber" 
            placeholder="Phone Number" 
            value={formData.phoneNumber} 
            onChange={handleChange} 
            required 
            className="w-3/4 max-w-sm px-4 py-3 rounded-md bg-gray-100 border border-gray-300 focus:outline-none" />
          <input 
            type="password" 
            name="password" 
            placeholder="Password" 
            value={formData.password} 
            onChange={handleChange} 
            required 
            className="w-3/4 max-w-sm px-4 py-3 rounded-md bg-gray-100 border border-gray-300 focus:outline-none" />
          <input 
            type="password" 
            name="confirmPassword" 
            placeholder="Confirm Password" 
            value={formData.confirmPassword} 
            onChange={handleChange} 
            required 
            className="w-3/4 max-w-sm px-4 py-3 rounded-md bg-gray-100 border border-gray-300 focus:outline-none" />

          <div className="flex items-center mt-4 w-3/4 max-w-sm">
            <input type="checkbox" name="agreeToTerms" checked={formData.agreeToTerms} onChange={handleChange} required className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-400" />
            <label className="ml-2 text-sm text-gray-700">
              I agree to the <a href='https://www.nusmark.com/privacy-policy' target="_blank" rel="noopener noreferrer" className='text-blue-600 underline'>terms and conditions</a>
            </label>
          </div>

          <button 
            type="submit" 
            disabled={isLoading} 
            className={`w-3/4 max-w-sm py-3 mt-6 font-semibold rounded-md text-white ${isLoading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}>
            {isLoading ? "Registering..." : "Register"}
          </button>
        </form>
        
        <p className="mt-6 text-center text-sm text-gray-700">
          Already have an account? <Link to="/login" className="text-blue-600 underline">Login instead</Link>
        </p>
        
        <Validation
          isOpen={isValidationOpen}
          onClose={() => setIsValidationOpen(false)}
          message={`Please enter the code sent to ${formData.email}`}
          onValidate={handleValidate}
          onResend={handleResend}
        />
      </div>
      
        <AnimatedBackground animationName="cosmicDust" />      
    </div>
  );
}
