import React, { useEffect, useState } from 'react';
import { useAuth } from '../services/authContext';
import { useNavigate } from 'react-router-dom';
import { UserIcon, LocationMarkerIcon, CashIcon } from '@heroicons/react/solid';
import DashboardTile from '../components/DashboardTile';
import { checkBilling } from '../services/apiServices';

export default function Dashboard() {
  const navigate = useNavigate();
  const [billingEmpty, setBillingEmpty] = useState(false);
  const { setIsAuthenticated } = useAuth();

  useEffect(() => {
    checkBilling({
      access_token: localStorage.getItem('token')
    })
    .then((response) => {
      if (response.status === 200 && response.data.complete === true) {
        setBillingEmpty(false);
      } else if (response.status === 200 && response.data.complete === false) {
        setBillingEmpty(true);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="relative min-h-screen">
      <div className="relative bg-white flex items-center flex-col min-h-screen pt-5">
        <h1 className="text-4xl font-bold text-center mb-8">Dashboard</h1>
        <div className="flex flex-wrap justify-center mb-6 mx-5 gap-2">
          <DashboardTile 
            icon={<UserIcon className="h-12 w-12 md:h-16 md:h-16 mb-2 text-gray-700" />} 
            title="Account" 
            notification={false} 
            onClick={() => navigate('./account')} 
            className="w-full sm:w-auto" 
          />
          <DashboardTile 
            icon={<LocationMarkerIcon className="h-12 w-12 md:h-16 md:h-16 mb-2 text-gray-700" />} 
            title="Locations" 
            notification={false} 
            onClick={() => navigate('./locations')} 
            className="w-full sm:w-auto" 
          />
          <DashboardTile 
            icon={<CashIcon className="h-12 w-12 md:h-16 md:h-16 mb-2 text-gray-700" />} 
            title="Billing" 
            notification={billingEmpty} 
            onClick={() => navigate('./billing')} 
            className="w-full sm:w-auto" 
          />
        </div>
        <button
          onClick={handleLogout}
          className="block w-44 mx-auto py-3 bg-red-500 text-white rounded-md hover:bg-red-400 transition duration-300 font-semibold shadow-md"
        >
          Log Out
        </button>
      </div>
    </div>
  );
  
}
