import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { loadLocations } from '../services/apiServices';
import { useAuth } from '../services/authContext';

export default function Locations() {
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const { setLocationId } = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get('message');

  useEffect(() => {
    if (message === 'success') {
      alert('Location has been added! Once your business has been verified, we\'ll start accepting locations.');
    }
  }, [message]);

  useEffect(() => {
    loadLocations({
      access_token: localStorage.getItem('token'),
    })
    .then((response) => {
      if (response.status === 200 && response.data.status === 'success') {
        setError('');
        setLocations(response.data.locations);
      } else {
        setError(response.message || 'Retrieval failed');
      }
    })
    .catch((err) => {
      console.error(err);
      setError(err.response?.data?.message || 'Retrieval failed');
    })
    .finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className="relative min-h-screen">
      <div className="relative bg-white flex items-center flex-col min-h-screen pt-5">

        <h2 className="text-4xl font-bold text-center mb-4">Locations</h2>

        <div className="flex justify-center gap-4 mb-4">
          <Link to="/dashboard">
            <button className="bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-600 transition duration-300">
              Back to Dashboard
            </button>
          </Link>
          <Link to="./add-location">
            <button className="bg-blue-900 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
              Add New Location
            </button>
          </Link>
        </div>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}

        {loading ? (
          <p className="text-center text-gray-500">Loading...</p>
        ) : locations.length === 0 ? (
          <p className="text-center text-gray-500 mx-3">
            No locations available. You can add a new location by clicking "Add New Location" above.
          </p>
        ) : (
          <div className="overflow-y-auto flex-grow w-full max-w-4xl">
            <ul className="space-y-4 w-full px-5">
              {locations.map((location) => (
                <Link to={`./${location.doc_ref}`} key={location.doc_ref} onClick={() => setLocationId(location.doc_ref)} className='w-full'>
                  <li className="w-full flex items-center justify-center space-x-4 mb-2 p-6 bg-white text-gray-800 border border-gray-300 rounded-lg shadow-md hover:shadow-lg hover:bg-gray-200 transition duration-300">
                    <img src={location.image_settings.url} alt="Building" className="w-20 h-20 rounded-md" />
                    <div className="flex-grow flex flex-col justify-center">
                      <span className="font-semibold text-lg">{location.location_name}</span>
                      <span className="text-sm text-gray-400">{location.address}</span>
                    </div>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        )}
        </div>
    </div>
  );
}
