import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateBilling, loadBilling } from '../services/apiServices';

export default function BillingInformation() {
  const [billingData, setBillingData] = useState({
    name: '',
    addressl1: '',
    addressl2: '',
    email: '',
    phoneNumber: '',
    vat: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [reportFrequency, setReportFrequency] = useState({
    daily: false,
    weekly: false,
    monthly: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    loadBilling({
      access_token: localStorage.getItem('token'),
    })
    .then((response) => {
      if (response) {
        const billingData = {
          name: response.data.billing_info.client_name,
          addressl1: response.data.billing_info.address_l1,
          addressl2: response.data.billing_info.address_l2 || '',
          email: response.data.billing_info.email,
          phoneNumber: response.data.billing_info.phone_number || '',
          vat: response.data.billing_info.vat_number || ''
        };
        const reportFrequency = {
          daily: response.data.billing_info.reports.send_daily_reports,
          weekly: response.data.billing_info.reports.send_weekly_reports,
          monthly: response.data.billing_info.reports.send_monthly_reports,
        };
        setBillingData(billingData);
        setReportFrequency(reportFrequency);
      } else {
        setError(response.message || 'Failed to load account info');
      }
    })
    .catch((err) => {
      console.error(err);
      setError('Failed to load account info');
    })
    .finally(() => {
      setLoading(false);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBillingData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleReportFrequencyChange = (e) => {
    const { name, checked } = e.target;
    setReportFrequency((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    updateBilling({
      access_token: localStorage.getItem('token'),
      billing_info: {
        client_name: billingData.name,
        address_l1: billingData.addressl1,
        address_l2: billingData.addressl2,
        email: billingData.email,
        phone_number: billingData.phoneNumber,
        vat_number: billingData.vat,
        reports: {
          send_daily_reports: reportFrequency.daily,
          send_weekly_reports: reportFrequency.weekly,
          send_monthly_reports: reportFrequency.monthly,
        },
      },
    })
    .then((response) => {
      if (response.status === 200) {
        setError('');
        navigate('/dashboard');
      } else {
        setError(response.message || 'Failed to update account info');
      }
    })
    .catch((err) => {
      console.error(err);
      setError('Failed to update account info');
    });
  };

  return (
    <div className="relative min-h-screen overflow-hidden">
      <div className="relative bg-white flex items-center flex-col min-h-screen p-5">

          <h1 className="text-4xl font-bold text-center mb-8">Billing Information</h1>

          {error && <p className="text-red-500 text-center mb-4">{error}</p>}

          <div className='w-full max-w-2xl mx-auto px-3 flex-grow mb-5'>
            <form onSubmit={handleSubmit} className="space-y-3">

              {/* Basic Information Section */}
              <div className="border-b border-gray-300 pb-4">
                <h2 className="text-xl font-semibold mb-4">Basic Information</h2>
                <div className="mb-4">
                  <label className="block mb-1 text-sm font-medium">Full Name or Business Name
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={billingData.name}
                    onChange={handleChange}
                    className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                    required
                  />
                </div>

                <div>
                  <label className="block mb-1 text-sm font-medium">Address (Street Address)
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="addressl1"
                    value={billingData.addressl1}
                    onChange={handleChange}
                    className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2 text-base font-medium">Address (City, Country, Post Code)</label>
                  <input
                    type="text"
                    name="addressl2"
                    value={billingData.addressl2}
                    onChange={handleChange}
                    className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-base font-medium">Email
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={billingData.email}
                    onChange={handleChange}
                    className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2 text-base font-medium">Phone Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={billingData.phoneNumber}
                    onChange={handleChange}
                    className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-base font-medium">Tax ID or VAT Number</label>
                  <input
                    type="text"
                    name="vat"
                    value={billingData.vat}
                    onChange={handleChange}
                    className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                  />
                </div>
              </div>

              {/* Reports Section */}
              <div className="border-b border-gray-300 pb-4">
                <h2 className="text-xl font-semibold mb-4">Reports</h2>
                <div className="mb-4">
                  <label className="block mb-1 text-sm font-medium">How often would you like to receive reports?</label>
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="daily"
                        name="daily"
                        checked={reportFrequency.daily}
                        onChange={handleReportFrequencyChange}
                        className="mr-2"
                      />
                      <label htmlFor="daily">Daily</label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="weekly"
                        name="weekly"
                        checked={reportFrequency.weekly}
                        onChange={handleReportFrequencyChange}
                        className="mr-2"
                      />
                      <label htmlFor="weekly">Weekly</label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="monthly"
                        name="monthly"
                        checked={reportFrequency.monthly}
                        onChange={handleReportFrequencyChange}
                        className="mr-2"
                      />
                      <label htmlFor="monthly">Monthly</label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Invoices Section */}
              <div className="border-b border-gray-300 pb-4">
                <h2 className="text-xl font-semibold mb-4">Invoices</h2>
                <div className="mb-4">
                  <label className="block mb-1 text-sm font-medium">Invoices will be sent monthly to your billing email address.</label>
                </div>
              </div>

              {/* Submit Buttons */}
              <div className="flex flex-wrap justify-end mt-4">
                <button
                  onClick={() => navigate('/dashboard')}
                  type="button"
                  className="order-2 mt-2 sm:mt-0 sm:order-1 bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded transition duration-300 w-full sm:w-auto"
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className={`order-1 sm:order-3 bg-blue-900 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 sm:ml-3 w-full sm:w-auto`}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
    </div>
  );
}
