import React, { useState } from "react";

const Validation = ({ isOpen, onClose, message, onValidate, onResend }) => {
  const [code, setCode] = useState("");

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
      <div className="bg-white bg-opacity-100 p-8 rounded-lg shadow-2xl max-w-sm w-full text-black">
        <h2 className="text-2xl font-bold mb-4 text-center">Validation</h2>
        <p className="text-center mb-6">{message}</p>

        <input
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter validation code"
          className="w-full max-w-sm px-4 py-3 mb-5 rounded-md bg-gray-100 border border-gray-300 focus:outline-none"
        />

        <div className="flex justify-between space-x-4">
        <button
            onClick={onResend}
            className="w-full bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            Resend Code
          </button>
          <button
            onClick={() => onValidate(code)}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            Validate
          </button>
        </div>

        <button
          onClick={onClose}
          className="mt-6 w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Validation;
