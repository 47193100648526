import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [isAuthenticated, setIsAuthenticated] = useState(
		() => localStorage.getItem('isAuthenticated') === 'true'	
	);

	const [businessName, setBusinessName] = useState(
		() => localStorage.getItem('businessName') || ''
	);

	const [locationId, setLocationId] = useState([]);

	useEffect(() => {
		localStorage.setItem('isAuthenticated', isAuthenticated);
	}, [isAuthenticated]);

	useEffect(() => {
		localStorage.setItem('businessName', businessName);
	}, [businessName]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, businessName, setBusinessName, locationId, setLocationId }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
