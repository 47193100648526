import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './services/authContext';
import { Navigate } from 'react-router-dom';
import ProtectedRoute from './services/protectedRoute';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Locations from './pages/Locations';
import AddLocation from './pages/AddLocation';
import Location from './pages/Location';
import Account from './pages/Account';
import Header from './components/Header';
import BillingInformation from './pages/BillingInformation';

export default function App() {

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={
            <ProtectedRoute routeType="login">
              <Login />
            </ProtectedRoute>
          } />
          <Route path="/register" element={
            <ProtectedRoute routeType="register">
              <Register />
            </ProtectedRoute>
          } />
          <Route path="/dashboard" element={
            <ProtectedRoute routeType="dashboard">
              <Header />
              <Dashboard />
            </ProtectedRoute>
          } />
          <Route path="/dashboard/account" element={
            <ProtectedRoute routeType="dashboard">
              <Header />
              <Account />
            </ProtectedRoute>
          } />
          <Route path="/dashboard/locations" element={
            <ProtectedRoute routeType="dashboard">
              <Header />
              <Locations />
            </ProtectedRoute>
          } />
          <Route path="/dashboard/locations/add-location" element={
            <ProtectedRoute routeType="dashboard">
              <Header />
              <AddLocation />
            </ProtectedRoute>
          } />
          <Route path="/dashboard/locations/*" element={
            <ProtectedRoute routeType="dashboard">
              <Header />
              <Location />
            </ProtectedRoute>
          } />
          <Route path="/dashboard/locations/not-found" element={<div>404 Not Found</div>} />
          <Route path="/dashboard/billing" element={
            <ProtectedRoute routeType="dashboard">
              <Header />
              <BillingInformation />
            </ProtectedRoute>
          } />
          <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>
      </Router>
    </AuthProvider>
  )
}