import React, { useEffect, useRef } from 'react';

const AddressInput = ({ onAddressSelect, onManualChange, text, disabled }) => {
  const addressInputRef = useRef(null);
  
  useEffect(() => {
    const loadGoogleMapsApi = () => {
      return new Promise((resolve) => {
        const existingScript = document.getElementById('google-maps');

        if (!existingScript) {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
          script.id = 'google-maps';
          script.onload = () => resolve(window.google);
          document.body.appendChild(script);
        } else {
          resolve(window.google);
        }
      });
    };

    loadGoogleMapsApi().then((google) => {
      const autocompleteInstance = new google.maps.places.Autocomplete(addressInputRef.current, {
        types: ['geocode', 'establishment'],
        componentRestrictions: { country: 'uk' },
      });

      autocompleteInstance.addListener('place_changed', () => {
        const place = autocompleteInstance.getPlace();
        if (!place.geometry) {
          alert("No details available for the selected address");
          return;
        }
        if (onAddressSelect) {
          onAddressSelect(place);
        }
      });
    });
  }, []);

  return (
    <input
      ref={addressInputRef}
      type="text"
      value={text}
      name='address'
      placeholder="Enter address"
      onChange={onManualChange}
      className="w-full px-4 py-3 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
      disabled={disabled}
      />
  );
};

export default AddressInput;
