import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { loadLocation, editLocation, deleteLocation, checkAccessName } from '../services/apiServices';
import AddressInput from '../components/AddressInput';
import { PlusIcon, XIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';

export default function Location() {
  const location = useLocation();
  const navigate = useNavigate();
  const locationId = location.pathname.split('/').pop();
  const [error, setError] = useState('');
  const [keywordInput, setKeywordInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [isAddressValid, setIsAddressValid] = useState(false);
  const [googleImages, setGoogleImages] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [isNameValid, setIsNameValid] = useState(false);
  const [initialAccessName, setInitialAccessName] = useState('');
  const [initialLocationName, setInitialLocationName] = useState('');
  const [formData, setFormData] = useState({
    locationId: '',
    displayName: '',
    description: '',
    accessName: '',
    accessCode: '',
    accessUrl: '',
    keywords: [],
    address: '',
    imageUrl: '',
    useGoogleImage: true,
    googlePlaceUrl: '',
    googlePlaceId: '',
    longitude: 0,
    latitude: 0,
    commission: 5,
    discount: 10,
    minSpend: 4.99,
    hideQrCode: false,
    firstTimeDiscount: false,
    firstTimeDiscountAmount: 15,
    firstTimeDiscountNote: '',
    countsTowardsLimit: false,
  });

  useEffect(() => {
    loadLocation({
      access_token: localStorage.getItem('token'),
      location_id: locationId,
    })
    .then((response) => {
      if (response.data) {
        
        setFormData({
          locationId: response.data.location.location_id,
          displayName: response.data.location.location_name,
          description: response.data.location.description || '',
          accessName: response.data.location.access_name || '',
          accessCode: response.data.location.access_passcode || '',
          keywords: response.data.location.key_words || [],
          address: response.data.location.address,
          imageUrl: response.data.location.image_settings.url,
          useGoogleImage: response.data.location.image_settings.use_google_image,
          googlePlaceUrl: response.data.location.google_place_url,
          googlePlaceId: response.data.location.google_place_id,
          longitude: response.data.location.longitude,
          latitude: response.data.location.latitude,
          commission: response.data.location.discounts.commission,
          discount: response.data.location.discounts.discount_amount,
          minSpend: response.data.location.discounts.min_spend,
          hideQrCode: response.data.location.discounts.hide_qr_code,
          firstTimeDiscount: response.data.location.discounts.ftcd,
          firstTimeDiscountAmount: response.data.location.discounts.ftcd_amount || 0,
          firstTimeDiscountNote: response.data.location.discounts.ftcd_note || '',
          countsTowardsLimit: response.data.location.discounts.ftcd_counts_towards_limit,
        });

        setInitialAccessName(response.data.location.access_name);
        setInitialLocationName(response.data.location.location_name);
      } else {
        navigate('/dashboard/locations/not-found');
      }
    })
    .catch((err) => {
      console.error(err);
      setError('Error fetching location');
    });
  }, [locationId, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // if (name === 'address') {
    //   setIsAddressValid(false);
    // }
  };

  // Function to check the accessName validity
  const handleAccessName = async (name) => {
    setFormData({ ...formData, accessName: name });
    
    if (name === '' || name === initialAccessName) {
      setValidationMessage('');
      setIsNameValid(false);
      return;
    }

    checkAccessName({
      access_token: localStorage.getItem('token'),
      access_name: name,
    })
    .then((response) => {
      if (response.data.is_taken === false) {
        setValidationMessage('Access name is available');
        setIsNameValid(true);
      } else {
        setValidationMessage('Access name is already taken');
        setIsNameValid(false);
      }
    })
  };

  const handleSliderChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleAdjustMinSpend = (amount) => {
    const newValue = Math.max(4.99, parseFloat((formData.minSpend + amount).toFixed(2)));
    handleChange({
      target: {
        name: 'minSpend',
        value: newValue,
      },
    });
  };

  const handleAddKeyword = () => {
    const trimmedKeyword = keywordInput.trim();
  
    const existingKeywordsLowercase = formData.keywords.map((keyword) => keyword.toLowerCase());
  
    if (
      trimmedKeyword !== '' && 
      formData.keywords.length < 10 && 
      !existingKeywordsLowercase.includes(trimmedKeyword.toLowerCase())
    ) {
      setFormData({
        ...formData,
        keywords: [...formData.keywords, trimmedKeyword],
      });
      setKeywordInput('');
    }
  };

  const handleRemoveKeyword = (keywordToRemove) => {
    setFormData({
      ...formData,
      keywords: formData.keywords.filter((keyword) => keyword !== keywordToRemove),
    });
  };

  // const handleAddressSelect = (place) => {
  //   const address = place.formatted_address;
  //   const photos = place.photos || [];
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     address,
  //     googlePlaceUrl: place.url,
  //     longitude: place.geometry.location.lng(),
  //     latitude: place.geometry.location.lat(),
  //     googlePlaceId: place.place_id,
  //     imageUrl: photos.length > 0 ? photos[0].getUrl() : '',
  //   }));
  //   setIsAddressValid(true);
  //   setGoogleImages(photos.map((photo) => photo.getUrl()));
  // };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this location?')) {
      setIsLoading(true);
      deleteLocation({
        access_token: localStorage.getItem('token'),
        location_id: locationId,
      })
      .then((response) => {
        if (response.data.status === 'success') {
          navigate('/dashboard/locations');
        } else {
          setError(response.message || 'Deletion failed');
          scrollToTop(); 
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.response.data.message || 'Deletion failed');
        scrollToTop();
      })
      .finally(() => setIsLoading(false));
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.firstTimeDiscount && (formData.firstTimeDiscountAmount < formData.discount + 5)) {
      setError('First-time discount must be at least 5% bigger than the regular discount');
      scrollToTop();
      return;
    }    

    setIsLoading(true);

    try {
      const locationData = {
        access_token: localStorage.getItem('token'),
        location_id: locationId,
        location_name: formData.displayName,
        key_words: formData.keywords,
        description: formData.description,
        latitude: formData.latitude,
        longitude: formData.longitude,
        google_place_id: formData.googlePlaceId,
        google_place_url: formData.googlePlaceUrl,
        discount_amount: formData.discount,
        commission: formData.commission,
        min_spend: formData.minSpend,
        image_settings: {
          url: formData.imageUrl,
          use_google_image: formData.useGoogleImage,
        },
        hide_qr_code: formData.hideQrCode,
        ftcd: formData.firstTimeDiscount,
        ftcd_amount: formData.firstTimeDiscountAmount,
        ftcd_note: formData.firstTimeDiscountNote,
        ftcd_counts_towards_limit: formData.countsTowardsLimit,
        access_passcode: formData.accessCode,
      };
      
      if (formData.accessName !== initialAccessName) {
        locationData.access_name = formData.accessName;
      }
      
      editLocation(
        locationData
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === 'success') {
          setError('');
          navigate('/dashboard/locations');
        } else {
          setError(response.message || 'Updating failed');
          scrollToTop();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.response.data.message || 'Updating failed');
        scrollToTop();
        setIsLoading(false);
      });
    } catch (err) {
      console.error(err);
      setError('Updating failed');
      scrollToTop();
      setIsLoading(false);
    }
  };

  return (
    <div className="relative min-h-screen">
      {/* Page Wrapper */}
      <div className="relative bg-white flex items-center flex-col min-h-screen pt-5">
        {/* Page Title */}
        <h1 className="text-3xl sm:text-4xl font-bold text-center mb-8">Edit {initialLocationName}</h1>
  
        {/* Error Message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}
  
        <div className="w-full max-w-2xl mx-auto flex-grow mb-5 px-3">
          <form onSubmit={handleSubmit} className="space-y-4">
            
            {/* Basic Information Section */}
            <div className="border-b border-gray-300 pb-4">
              <h2 className="text-xl font-semibold mb-4">Basic Information</h2>
              <div className="mb-4">
                <label className="block mb-1 text-md font-medium">Display Name</label>
                <input
                  type="text"
                  name="displayName"
                  value={formData.displayName}
                  onChange={handleChange}
                  className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                  required
                />
              </div>
              <div>
                <label className="block mb-1 text-md font-medium">Description</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                />
              </div>
            </div>

            <div>
              <label className="block mb-1 text-md font-medium">Access Name</label>
              <input
                name='accessName'
                type="text"
                value={formData.accessName}
                onChange={(e) => {
                  const name = e.target.value.toUpperCase().replace(/[^A-Z0-9_]/g, "");
                  handleAccessName(name);
                }}
                className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                />
              <a className='text-sky-600' target='_blank' rel='noreferrer' href={"https://redeem.nusmark.com"}>https://redeem.nusmark.com</a>

              <div
                className={`mt-2 text-sm ${isNameValid ? 'text-green-600' : 'text-red-600'}`}
              >
                {validationMessage}
              </div>
            </div>

            <div>
              <label className="block mb-1 text-md font-medium">Access Code</label>
              <input
                name='accessCode'
                type="text"
                value={formData.accessCode}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
              />
            </div>
  
            {/* Keywords Section */}
            <div className="border-b border-gray-300 pb-4">
              <h2 className="text-xl font-semibold mb-4">Keywords</h2>
              <label className="block mb-1 text-md font-medium">Keywords (Max 10)</label>
              <div className="flex items-center mb-2">
                <input
                  type="text"
                  value={keywordInput}
                  onChange={(e) => setKeywordInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAddKeyword();
                    }
                  }}
                  className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                />
                <button
                  type="button"
                  onClick={handleAddKeyword}
                  className="text-slate-500 text-nowrap flex flex-row justify-start items-center border border-blue-400 hover:bg-blue-600 hover:text-white text-base px-3 py-2 rounded ml-2 transition duration-300"
                  disabled={formData.keywords.length >= 10}
                >
                  <PlusIcon className="h-5 w-5" />
                  <p className="ml-2">Add Keyword</p>
                </button>
              </div>

              <div className="flex flex-wrap gap-2 justify-start items-center">
                {formData.keywords.map((keyword, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-gray-100 border border-gray-300 text-black rounded-md flex items-center"
                  >
                    {keyword}
                    <button
                      type="button"
                      onClick={() => handleRemoveKeyword(keyword)}
                      className="ml-2 text-red-900 font-bold"
                    >
                      <XIcon className="h-4 w-4" />
                    </button>
                  </span>
                ))}
              </div>
            </div>
  
            {/* Address Section*/}
            <div className="border-b border-gray-300 pb-4">
              <h2 className="text-xl font-semibold mb-4">Address</h2>
              <label className="block mb-1 text-sm font-medium">Address</label>
              <AddressInput text={formData.address} disabled={true} />
              {/* <AddressInput onAddressSelect={handleAddressSelect} onManualChange={handleChange} /> */}
            </div>
  
            {/* Image Selection Section*/}
            {/* {isAddressValid && ( */}
              <div className="border-b border-gray-300 pb-4">
                <h2 className="text-xl font-semibold mb-4">Image Selection</h2>
                <div className="flex items-center mb-4 space-x-4">
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="imageSource"
                      value="google"
                      checked={formData.useGoogleImage}
                      onChange={() => {
                        setFormData({ ...formData, useGoogleImage: true });
                      }}
                    />
                    <span>Use Default Google Image</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="imageSource"
                      value="manual"
                      checked={!formData.useGoogleImage}
                      onChange={() => setFormData({ ...formData, useGoogleImage: false})}
                    />
                    <span>Provide URL Manually</span>
                  </label>
                </div>
  
                {formData.useGoogleImage && (
                  <img
                    alt="Default Google Images"
                    className="h-80 w-80 mb-4"
                    src={formData.imageUrl}
                  />
                )}
  
                {!formData.useGoogleImage && (
                  <div className="mt-2">
                    <label className="block mb-1 text-sm font-medium">Image URL</label>
                    <input
                      type="text"
                      name="manualImageUrl"
                      value={formData.imageUrl}
                      onChange={(e) => setFormData({ ...formData, imageUrl: e.target.value })}
                      className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                      placeholder="Enter image URL"
                    />
                  </div>
                )}
              </div>
            {/* )} */}
  
            {/* Settings Section */}
            <div className="border-b border-gray-300 pb-4">
              <h2 className="text-xl font-semibold mb-4">Settings</h2>

              {/* Hide QR Code */}
              <div className="my-2">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="hideQRCode"
                    checked={formData.hideQrCode}
                    onChange={(e) => setFormData({ ...formData, hideQrCode: e.target.checked })}
                  />
                  <span>Hide QR Code</span>
                </label>
              </div>

              {/* Slider's Section */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium">Commission (1% Increments, min 5%)</label>
                <input
                  type="range"
                  min="5"
                  max="40"
                  value={formData.commission}
                  onChange={(e) => handleSliderChange('commission', parseFloat(e.target.value))}
                  className="w-full"
                />
                <p>{formData.commission}%</p>
              </div>

              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium">Discount (2.5% Increments, min 10%)</label>
                <input
                  type="range"
                  min="10"
                  max="30"
                  step={2.5}
                  value={formData.discount}
                  onChange={(e) => handleSliderChange('discount', parseFloat(e.target.value))}
                  className="w-full"
                />
                <p>{formData.discount}%</p>
              </div>
              
              {/* Min Spend Section */}
              <div>
                <label className="block mb-2 text-sm font-medium">
                  Minimum Spend (min £4.99)
                </label>

                <div className="flex flex-wrap items-center justify-center">
                  <input
                    type="text"
                    value={formData.minSpend}
                    readOnly
                    className="w-full px-3 py-2 mb-2 text-center rounded-md bg-gray-100 border order-1 border-gray-300 sm:order-2 sm:w-auto sm:mb-0"
                  />

                  <div className="flex flex-wrap justify-center flex-col-reverse w-full sm:space-x-0 order-3 sm:order-1 sm:flex-row sm:w-auto">
                    <button
                      type='button'
                      onClick={() => handleAdjustMinSpend(-5.0)}
                      className="px-3 py-2 bg-red-500 text-white"
                    >
                      -5.00
                    </button>
                    <button
                    type='button'
                      onClick={() => handleAdjustMinSpend(-1.0)}
                      className="px-3 py-2 bg-red-400 text-white"
                    >
                      -1.00
                    </button>
                    <button
                    type='button'
                      onClick={() => handleAdjustMinSpend(-0.5)}
                      className="px-3 py-2 bg-red-300 text-white"
                    >
                      -0.50
                    </button>
                  </div>

                  <div className="flex flex-wrap justify-center w-full order-2 flex-col sm:space-x-0 sm:order-3 sm:w-auto sm:flex-row">
                    <button
                    type='button'
                      onClick={() => handleAdjustMinSpend(0.5)}
                      className="px-3 py-2 bg-green-300 text-white"
                    >
                      +0.50
                    </button>
                    <button
                    type='button'
                      onClick={() => handleAdjustMinSpend(1.0)}
                      className="px-3 py-2 bg-green-400 text-white"
                    >
                      +1.00
                    </button>
                    <button
                    type='button'
                      onClick={() => handleAdjustMinSpend(5.0)}
                      className="px-3 py-2 bg-green-500 text-white"
                    >
                      +5.00
                    </button>
                  </div>
                </div>
              </div>
            </div>

          {/* First-Time Discount Section */}
          <div className="border-b border-gray-300 pb-4">

            <h2 className="text-xl font-semibold mb-4 flex items-center">Offer a First-Time Discount?
                <QuestionMarkCircleIcon 
                className="h-5 w-5 inline-block text-gray-500 ml-1 cursor-pointer"
                onClick={() => setIsPopupVisible(!isPopupVisible)}
                />
            </h2>

            {/* Popup */}
            {isPopupVisible && (
              <div 
                className="fixed inset-0 bg-black bg-opacity-50 z-20 flex justify-center items-center"
                onClick={() => setIsPopupVisible(false)} 
              >
                <div
                  className="p-4 bg-white border border-gray-300 rounded-lg shadow-lg text-sm max-w-sm"
                  onClick={(e) => e.stopPropagation()} 
                >
                  <p>
                    First-time customers visiting your establishment through Nusmark are 
                    eligible for an exclusive introductory discount, which can be configured 
                    by you in this section.
                  </p>
                  <button
                    onClick={() => setIsPopupVisible(false)}
                    className="mt-2 text-blue-500 underline"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}

            <div className="mb-4">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="firstTimeDiscount"
                  value="yes"
                  checked={formData.firstTimeDiscount}
                  onChange={() => setFormData({ ...formData, firstTimeDiscount: true })}
                />
                <span>Yes</span>
              </label>

              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="firstTimeDiscount"
                  value="no"
                  checked={!formData.firstTimeDiscount}
                  onChange={() => setFormData({ ...formData, firstTimeDiscount: false })}
                />
                <span>No</span>
              </label>
            </div>

            {/* First-Time Discount Slider */}
            {formData.firstTimeDiscount && (
              <>
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium">
                    Discount (2.5% increments, min 15%)
                  </label>
                  <input
                    type="range"
                    min="15"
                    max="75"
                    step={2.5}
                    value={formData.firstTimeDiscountAmount}
                    onChange={(e) => handleSliderChange("firstTimeDiscountAmount", parseFloat(e.target.value))}
                    className="w-full"
                  />
                  <p>{formData.firstTimeDiscountAmount}%</p>
                </div>

                {/* Discount Note */}
                <div className="mb-4 relative">
                  <label className="block mb-2 text-sm font-medium">Discount Note</label>
                  <textarea
                    name="discountNote"
                    value={formData.firstTimeDiscountNote}
                    onChange={(e) => {
                      if (e.target.value.length <= 50) {
                        setFormData({
                          ...formData,
                          firstTimeDiscountNote: e.target.value,
                        });
                      }
                    }}
                    className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                    placeholder="Enter details and conditions about the discount..."
                  />
                  {/* Character Count */}
                  <p className="text-sm text-gray-500 absolute bottom-1 right-2">
                    {formData.firstTimeDiscountNote.length}/50
                  </p>
                </div>

                {/* Counts Towards Daily Limit */}
                <div className="mb-4">
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      name="countsTowardsLimit"
                      checked={formData.countsTowardsLimit}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          countsTowardsLimit: e.target.checked,
                        })
                      }
                    />
                    <span>Counts Towards Daily Limit</span>
                  </label>
                </div>
              </>
            )}
            </div>
  
            {/* Form Actions Section */}
            <div className="flex flex-col sm:flex-row justify-end mt-4 flex-wrap">
              <button
                type="button"
                onClick={() => navigate('/dashboard/locations')}
                className="order-3 mt-2 sm:mt-0 sm:order-1 bg-gray-700 hover:bg-gray-600 sm:ml-3 text-white px-4 py-2 rounded"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleDelete}
                className="order-2 mt-2 sm:mt-0 bg-red-500 hover:bg-red-400 sm:ml-3 text-white px-4 py-2 rounded"
              >
                Delete
              </button>
              <button
                type="submit"
                // disabled={isLoading || !isAddressValid}
                disabled={isLoading}
                className={`order-1 sm:order-3 bg-blue-900 text-white px-4 py-2 rounded hover:bg-blue-600 sm:ml-3 transition duration-300 ${
                  isLoading /*|| !isAddressValid*/ ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {isLoading ? 'Updating...' : 'Update'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
  
}
