import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './authContext';

const ProtectedRoute = ({ children, routeType }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated && (routeType === "login" || routeType === "register")) {
    return <Navigate to="/dashboard" />;
  }

  if (!isAuthenticated && routeType === "dashboard") {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
