import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadAccount, updateAccount } from '../services/apiServices';

export default function Account() {
  const [accountData, setAccountData] = useState({
    businessName: '',
    email: '',
    phoneNumber: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    loadAccount({
      access_token: localStorage.getItem('token'),
    })
      .then((response) => {
        if (response) {
          const data = {
            businessName: response.data.account.business_name,
            email: response.data.account.emails,
            phoneNumber: response.data.account.phone_numbers,
          };
          setAccountData(data);
        } else {
          setError(response.message || 'Failed to load account info');
        }
      })
      .catch((err) => {
        console.error(err);
        setError('Failed to load account info');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccountData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateAccount({
      access_token: localStorage.getItem('token'),
      update_dict: {
        business_name: accountData.businessName,
        emails: accountData.email,
        phone_numbers: accountData.phoneNumber,
      },
    })
      .then((response) => {
        if (response.status === 'success') {
          setError('');
        } else {
          setError(response.message || 'Failed to update account info');
        }
      })
      .catch((err) => {
        console.error(err);
        setError('Failed to update account info');
      });
  };

  return (
    <div className="relative min-h-screen overflow:hidden">
      <div className="relative bg-white flex items-center flex-col min-h-screen p-5">

          <h1 className="text-4xl font-bold text-center mb-8">Account</h1>

          {error && <p className="text-red-500 text-center mb-4">{error}</p>}

          {loading ? (
            <p className="text-center text-gray-500">Loading...</p>
          ) : (
            <div className='w-full max-w-2xl mx-auto px-3 flex-grow mb-5'>
              <form onSubmit={handleSubmit} className="space-y-3">
                  <div>
                    <label className="block mb-1 text-sm font-medium">Business Name</label>
                    <input
                      type="text"
                      name="businessName"
                      value={accountData.businessName}
                      onChange={handleChange}
                      className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                      disabled={true}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-base font-medium">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={accountData.email}
                      onChange={handleChange}
                      className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                      disabled={true}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-base font-medium">Phone Number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      value={accountData.phoneNumber}
                      onChange={handleChange}
                      className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                      disabled={true}
                    />
                  </div>
                <div className="flex flex-wrap justify-end mt-4">
                  <button
                    onClick={() => navigate('/dashboard')}
                    type="button"
                    className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                  >
                    Cancel
                  </button>
                  {/*
                  <button
                    type="submit"
                    className="bg-blue-900 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                  >
                    Update
                  </button>
                  */}
                </div>
              </form>
            </div>
          )}
        </div>
    </div>
  );
}
