import React from "react";
import NusmarkLogoDark from "../media/Nusmark-Logo-Black-Lighter-Smoothed.png";

export default function Header() {
  return (
    <div className="flex flex-col items-center md:flex-row md:items-end md:justify-start w-full pt-10 pl-10 pr-10">
      <img
        src={NusmarkLogoDark}
        alt="Nusmark Logo"
        className="h-8 md:h-9 object-contain"
      />
      <p className="ml-2 text-sm leading-none text-slate-400">Ver {process.env.REACT_APP_VERSION}</p>
    </div>
  );
}
