import React, { useState } from "react";
import { login, isVerified, verify, resendEmail } from '../services/apiServices';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from "../services/authContext";
import Validation from "../components/Validation";
import NusmarkLogoLight from "../media/Nusmark-Logo-White-Lighter-Smoothed.png";
import NusmarkLogoDark from "../media/Nusmark-Logo-Black-Lighter-Smoothed.png";
import { AnimatedBackground } from 'animated-backgrounds';

export default function Login() {
  const { setIsAuthenticated, setBusinessName } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ businessName: '', password: '', keepLoggedIn: false });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValidationOpen, setIsValidationOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    login({ business_name: formData.businessName, password: formData.password })
      .then(async (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          setError('');
          localStorage.setItem('token', response.data.token);

          const isVerifiedResponse = await isVerified({ access_token: response.data.token });
          if (isVerifiedResponse.data.verified) {
            setIsAuthenticated(true);
            setBusinessName(formData.businessName);
            navigate("/dashboard");
          } else {
            setIsValidationOpen(true);
            setIsLoading(false);
          }
        } else {
          setError(response.message || 'Login failed');
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.response.data.message || 'Login failed');
        setIsLoading(false);
      });
  };

  const handleValidate = (code) => {
    verify({
      access_token: localStorage.getItem('token'),
      verification_code: code,
    })
      .then((response) => {
        if (response.status === 200 && response.data.status === 'success') {
          setIsAuthenticated(true);
          navigate("/dashboard");
        } else {
          setError(response.message || 'Validation failed');
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.response.data.message || 'Validation failed');
      });
  };

  const handleResend = () => {
    try {
      resendEmail({ access_token: localStorage.getItem('token') })
      .then((response) => {
        if (response.status === 200 && response.data.status === 'success') {
          window.alert('Verification code resent');
        } else {
          setError(response.message || 'Resend failed');
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.response.data.message || 'Resend failed');
      });
    } catch (err) {
      console.error(err);
      setError('Resend failed');
    }
  };

  return (
    <div className="relative flex flex-col justify-center md:flex-row md:justify-start min-h-screen">
      
      <div className="flex flex-col items-center justify-center bg-white w-full md:w-1/2 p-6 md:p-10 lg:p-16 text-gray-900 shadow-lg">
        <img src={NusmarkLogoDark} alt="Nusmark Logo" className="h-12 w-auto mb-10" />
        <h2 className="text-4xl font-bold text-center mb-6">LOGIN</h2>
        {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
        
        <form onSubmit={handleSubmit} className="w-full space-y-4 flex flex-col items-center">
          <input 
            type="text" 
            name="businessName" 
            placeholder="Business Name" 
            value={formData.businessName} 
            onChange={handleChange} 
            required 
            className="w-3/4 max-w-sm px-4 py-3 rounded-md bg-gray-100 border border-gray-300 focus:outline-none" />
          <input 
            type="password" 
            name="password" 
            placeholder="Password" 
            value={formData.password} 
            onChange={handleChange} 
            required 
            className="w-3/4 max-w-sm px-4 py-3 rounded-md bg-gray-100 border border-gray-300 focus:outline-none" />

          <div className="flex items-center mt-4 w-3/4 max-w-sm">
            <input type="checkbox" name="keepLoggedIn" checked={formData.keepLoggedIn} onChange={handleChange} className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-400" />
            <label className="ml-2 text-sm text-gray-700">
              Keep me logged in
            </label>
          </div>

          <button 
            type="submit" 
            disabled={isLoading} 
            className={`w-3/4 max-w-sm py-3 mt-6 font-semibold rounded-md text-white ${isLoading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}>
            {isLoading ? "Logging in..." : "Sign in"}
          </button>
        </form>
        
        <p className="mt-6 text-center text-sm text-gray-700">
          Don’t have an account? <Link to="/register" className="text-blue-600 underline">Create one</Link>
        </p>
        
        <Validation
          isOpen={isValidationOpen}
          onClose={() => setIsValidationOpen(false)}
          message={`Please enter the code sent to your email`}
          onValidate={handleValidate}
          onResend={handleResend}
        />
      </div>
      
      <AnimatedBackground animationName="cosmicDust" />      
    </div>
  );
}
